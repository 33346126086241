<template>
  <div class="home tab" :style="{height:type===3?homeHeight:'100%'}" style="background:#F1F1F1;">
    <!-- 有声阅读 -->
    <el-tabs
      v-model="gid"
      style="margin-bottom: 50px; padding: 20px 40px;height: calc(100% - 60px);"
      @tab-click="onHandleClick"
    >
      <el-tab-pane
        v-for="(item) in grouping"
        :key="item.id"
        :label="item.name"
        :name="item.id"
        style="height: calc(100%);"
      >
        <span slot="label" class="tab-name">&nbsp;&nbsp;&nbsp;{{ item.name }}&nbsp;&nbsp;&nbsp;</span>
        <el-scrollbar class="scrollbar" style="height: calc(100%)">
          <!-- 多情景语块 -->
          <div v-if="item.name==='多情景语块'" class="row video-list">
            <div v-if="scenariosType!=2" class="row" style="flex-wrap: wrap;">
              <!-- 上一级 -->
              <div v-if="scenariosType!=2" style="margin:10px;height: 100%;">
                <div v-if="scenariosType!=0" class="column video">
                  <div
                    class="video-img"
                    style="height: 150px;width: 230px;background: rgb(49 49 49);"
                    @click="onScenarios(0)"
                  >
                    <svg
                      t="1661839587040"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="1432"
                      width="60"
                      height="60"
                    >
                      <path
                        d="M81.16 412.073333L0 709.653333V138.666667a53.393333 53.393333 0 0 1 53.333333-53.333334h253.413334a52.986667 52.986667 0 0 1 37.713333 15.62l109.253333 109.253334a10.573333 10.573333 0 0 0 7.54 3.126666H842.666667a53.393333 53.393333 0 0 1 53.333333 53.333334v74.666666H173.773333a96.2 96.2 0 0 0-92.613333 70.74z m922-7.113333a52.933333 52.933333 0 0 0-42.386667-20.96H173.773333a53.453333 53.453333 0 0 0-51.453333 39.333333L11.773333 828.666667a53.333333 53.333333 0 0 0 51.453334 67.333333h787a53.453333 53.453333 0 0 0 51.453333-39.333333l110.546667-405.333334a52.953333 52.953333 0 0 0-9.073334-46.373333z"
                        fill="#17abe3"
                      />
                    </svg>
                  </div>
                  <div class="column content">
                    <span class="video-name">上一级</span>
                    <span class="video-tag">上一级</span>
                  </div>
                </div>
              </div>
              <!-- 子分组 -->
              <div
                v-for="(sl) in scenariosList"
                :key="sl.name"
                style="margin:10px;height: 100%;"
                @click="onScenarios(sl.type,sl.name)"
              >
                <div class="column video">
                  <div
                    class="video-img"
                    style="height: 150px;width: 230px;background: rgb(49 49 49);"
                  >
                    <svg
                      t="1661839587040"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="1432"
                      width="60"
                      height="60"
                    >
                      <path
                        d="M81.16 412.073333L0 709.653333V138.666667a53.393333 53.393333 0 0 1 53.333333-53.333334h253.413334a52.986667 52.986667 0 0 1 37.713333 15.62l109.253333 109.253334a10.573333 10.573333 0 0 0 7.54 3.126666H842.666667a53.393333 53.393333 0 0 1 53.333333 53.333334v74.666666H173.773333a96.2 96.2 0 0 0-92.613333 70.74z m922-7.113333a52.933333 52.933333 0 0 0-42.386667-20.96H173.773333a53.453333 53.453333 0 0 0-51.453333 39.333333L11.773333 828.666667a53.333333 53.333333 0 0 0 51.453334 67.333333h787a53.453333 53.453333 0 0 0 51.453333-39.333333l110.546667-405.333334a52.953333 52.953333 0 0 0-9.073334-46.373333z"
                        fill="#17abe3"
                      />
                    </svg>
                  </div>
                  <div class="column content">
                    <span class="video-name">{{ sl.name }}</span>
                    <span class="video-tag">{{ sl.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="row video-list">
              <!-- 上一级 -->
              <div v-if="scenariosType!=0" class="column video">
                <div
                  class="video-img"
                  style="height: 150px;width: 230px;background: rgb(49 49 49);"
                  @click="onScenarios(0)"
                >
                  <svg
                    t="1661839587040"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1432"
                    width="60"
                    height="60"
                  >
                    <path
                      d="M81.16 412.073333L0 709.653333V138.666667a53.393333 53.393333 0 0 1 53.333333-53.333334h253.413334a52.986667 52.986667 0 0 1 37.713333 15.62l109.253333 109.253334a10.573333 10.573333 0 0 0 7.54 3.126666H842.666667a53.393333 53.393333 0 0 1 53.333333 53.333334v74.666666H173.773333a96.2 96.2 0 0 0-92.613333 70.74z m922-7.113333a52.933333 52.933333 0 0 0-42.386667-20.96H173.773333a53.453333 53.453333 0 0 0-51.453333 39.333333L11.773333 828.666667a53.333333 53.333333 0 0 0 51.453334 67.333333h787a53.453333 53.453333 0 0 0 51.453333-39.333333l110.546667-405.333334a52.953333 52.953333 0 0 0-9.073334-46.373333z"
                      fill="#17abe3"
                    />
                  </svg>
                </div>
                <div class="column content">
                  <span class="video-name">上一级</span>
                  <span class="video-tag">上一级</span>
                </div>
              </div>
              <div
                v-for="(video) in scenariosList"
                :key="video.vid"
                style="margin:10px;height: 100%;"
                @click="onVideoClick(video.md5,video.id)"
              >
                <div class="column video">
                  <el-image class="video-img" fit="cover" :src="video.cover" lazy />
                  <div class="column content">
                    <span class="video-name">{{ video.name }}</span>
                    <span class="video-tag">{{ video.tag }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 正常视频分组 -->
          <div v-else class="row video-list">
            <!-- 上一级 -->
            <div v-if="pgid.length>1" style="margin:10px;height: 100%;" @click="onLastGrouping()">
              <div class="column video">
                <div
                  class="video-img"
                  style="height: 150px;width: 230px;background: rgb(49 49 49);"
                >
                  <svg
                    t="1661839587040"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1432"
                    width="60"
                    height="60"
                  >
                    <path
                      d="M81.16 412.073333L0 709.653333V138.666667a53.393333 53.393333 0 0 1 53.333333-53.333334h253.413334a52.986667 52.986667 0 0 1 37.713333 15.62l109.253333 109.253334a10.573333 10.573333 0 0 0 7.54 3.126666H842.666667a53.393333 53.393333 0 0 1 53.333333 53.333334v74.666666H173.773333a96.2 96.2 0 0 0-92.613333 70.74z m922-7.113333a52.933333 52.933333 0 0 0-42.386667-20.96H173.773333a53.453333 53.453333 0 0 0-51.453333 39.333333L11.773333 828.666667a53.333333 53.333333 0 0 0 51.453334 67.333333h787a53.453333 53.453333 0 0 0 51.453333-39.333333l110.546667-405.333334a52.953333 52.953333 0 0 0-9.073334-46.373333z"
                      fill="#17abe3"
                    />
                  </svg>
                </div>
                <div class="column content">
                  <span class="video-name">上一级</span>
                  <span class="video-tag">上一级</span>
                </div>
              </div>
            </div>
            <!-- 子分组 -->
            <div v-for="(cg) in childGroup" :key="cg.name" style="margin:10px;height: 100%;">
              <div class="column video">
                <div
                  class="video-img"
                  style="height: 150px;width: 230px;background: rgb(49 49 49);"
                  @click="onGrouping(cg.id)"
                >
                  <svg
                    t="1661839587040"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1432"
                    width="60"
                    height="60"
                  >
                    <path
                      d="M81.16 412.073333L0 709.653333V138.666667a53.393333 53.393333 0 0 1 53.333333-53.333334h253.413334a52.986667 52.986667 0 0 1 37.713333 15.62l109.253333 109.253334a10.573333 10.573333 0 0 0 7.54 3.126666H842.666667a53.393333 53.393333 0 0 1 53.333333 53.333334v74.666666H173.773333a96.2 96.2 0 0 0-92.613333 70.74z m922-7.113333a52.933333 52.933333 0 0 0-42.386667-20.96H173.773333a53.453333 53.453333 0 0 0-51.453333 39.333333L11.773333 828.666667a53.333333 53.333333 0 0 0 51.453334 67.333333h787a53.453333 53.453333 0 0 0 51.453333-39.333333l110.546667-405.333334a52.953333 52.953333 0 0 0-9.073334-46.373333z"
                      fill="#17abe3"
                    />
                  </svg>
                </div>
                <div class="column content">
                  <span class="video-name">{{ cg.name }}</span>
                  <span class="video-tag">{{ cg.name }}</span>
                </div>
              </div>
            </div>
            <div
              v-for="(video) in videoListTemp"
              :key="video.vid"
              style="margin:10px;height: 100%;"
              @click="onVideoClick(video.md5,video.id)"
            >
              <div class="column video">
                <el-image
                  v-if="video.url.includes('mp3')"
                  fit="cover"
                  class="video-img"
                  :src="require('@/assets/mp3-logo.jpg')"
                />
                <el-image v-else class="video-img" fit="cover" :src="video.cover" />
                <div class="column content">
                  <span class="video-name">{{ video.name }}</span>
                  <span class="video-tag">{{ video.tag }}</span>
                </div>
              </div>
            </div>
            <el-pagination
              v-if="videoList.length>20"
              :page-size="20"
              layout="prev, pager, next"
              :total="videoList.length"
              style="position: fixed;bottom: 50px;"
              @current-change="videoListIndex"
            />
          </div>
        </el-scrollbar>
      </el-tab-pane>
    </el-tabs>
    <div class="bott">
      <span>英语习得教育 © 2021 版权所有</span>
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51015602000228"
        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
      >
        <img
          src="../icons/record.png"
          style=" float: left; width: 17px;height: 17px;margin-right: 3px;"
        />
        川公网安备 ICP备2021011205号-3
      </a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { getDictionariesList } from '@/apis/sys'
import { getVideoListNot, usTvResource } from '@/apis/video'
export default {
  name: 'Index',
  data() {
    return {
      dir: '', // 视频目录
      search: '', // 搜索
      visible: false,
      searchHeight: 0,
      searchVideoList: [], // 搜索视频集合
      imgCodeUrl: '', // 图形验证码地址
      imgUuid: '', // 图形验证码uuid
      imgCode: '', // 图形验证码Code
      smsCode: '', // 短信验证码
      homeHeight: '100%', // 高度
      grouping: [], // 分组
      childGroup: [], // 子分组
      gid: '', // 选择的分组
      pgid: [], // 选择的子分组上级集合
      page: {
        index: 1,
        rows: 100000
      },
      videoList: [], // 视频数组
      videoListTemp: [], // 视频分页
      isLogin: false, // 是否打开登录窗
      account: '', // 账号
      pass: '', // 密码
      savePass: true, // 记住密码
      isSet: false, // 是否打开设置窗口
      type: 1, // 加载类型
      bookInfo: {}, // 电子书信息
      bookCataList: [], // 目录
      bookBody: '', // 正文
      selectCid: '1', // 选择的正文ID
      itemCackgroud: '#c61200', // 选择的项颜色
      isWxLogin: false, // 是否微信登录
      wxlogin: {
        appid: 'wx7ab9e59225d15d4a',
        scope: 'snsapi_login',
        redirect_uri: 'https://huilianyingyu.com?isbinding=False'
      }, // 微信登录信息
      isForgetPass: false, // 忘记密码
      usTVList: [], // 学习资源（美剧）
      userInfo: {}, // 用户数据
      serviceQrCode: false, // 未点击客服二维码弹窗
      scenarios: [
        {
          name: '1.初级',
          type: 1,
          list: [
            {
              name: '基础1',
              type: 2
            }
          ]
        },
        {
          name: '2.中级',
          type: 1,
          list: [
            {
              name: '低级1',
              type: 2
            }
          ]
        },
        {
          name: '3.高级',
          type: 1,
          list: [
            {
              name: '中级1',
              type: 2
            }
          ]
        }
      ], // 语块多情景文件夹集合
      scenariosList: [], // 多情景语块
      scenariosType: 0, // 多情景语块 当前文件夹深度
      scenariosName: '', // 多情景语块 当前文件夹名称
      scenariosName2: '', // 多情景语块 当前选择的二级名称
      searchShow: false
    }
  },
  mounted() {
    var that = this
    this.dir = this.$route.query.dir
    Vue.$openLocalFile(true)
    // 窗口变化
    window.onresize = function() {
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      if (isFull === undefined) {
        that.homeHeight = document.documentElement.clientHeight - 80 + 'px'
      }
      that.searchHeight = document.documentElement.clientHeight - 220
    }
  },
  created() {
    var that = this
    getDictionariesList({
      grouping: '有声阅读'
      // names: jurisdiction.videos
    }).then(res => {
      this.grouping = res.data
      if (this.dir) {
        var dirs = this.dir.split('-')
        this.gid = dirs[0]

        this.pgid = []
        this.pgid.push(this.gid)
        this.getGrouping(this.gid)
        if (dirs.length >= 3) {
          this.onScenarios(1, dirs[1])
          this.onScenarios(2, `${dirs[1]}-${dirs[2]}`)
        }
      } else {
        this.gid = this.grouping[0].id
        this.getGrouping(this.gid)

        this.pgid = []
        this.pgid.push(this.gid)
        this.getGrouping(this.gid)
      }
      this.onGetVideoList()
    })

    // 其它页面进入 是否需要切换类型
    var pagetype = this.$route.params.type
    if (pagetype) {
      Vue.$type(pagetype)
    }

    this.scenariosList = this.scenarios
    that.searchHeight = document.documentElement.clientHeight - 220
  },
  methods: {
    // tabs选择事件
    onHandleClick() {
      if (this.gid === '-1') {
        usTvResource().then(res => {
          this.usTVList = res.data
        })
      } else {
        this.page = {
          index: 1,
          rows: 100000
        }
        this.onGetVideoList()
      }
      this.pgid = []
      this.pgid.push(this.gid)
      this.getGrouping(this.gid)
    },
    // 加载视频列表
    onGetVideoList() {
      // 语块多情景不加载
      if (this.gid !== '8') {
        getVideoListNot({
          gid: this.gid,
          page: this.page
        }).then(res => {
          this.videoList = res.data
          this.videoListIndex(1)
        })
      } else {
        if (this.scenariosName2 && this.scenariosType === 2) {
          getVideoListNot({
            gid: this.gid,
            tag: this.scenariosName2,
            page: this.page
          }).then(res => {
            this.scenariosList = res.data
          })
        }
      }
    },
    // 视频点击事件
    onVideoClick(md5, id) {
      this.$router.push({
        path: '/readingplayer',
        query: {
          md5: md5,
          id: id
        }
      })
    },
    // 下一级
    onGrouping(gid) {
      this.childGroup = []
      this.pgid.push(gid)
      // 加载子分组
      this.getGrouping(gid)
      // 加载视频
      getVideoListNot({
        gid: gid,
        page: this.page
      }).then(res => {
        this.videoList = res.data
        this.videoListIndex(1)
        // console.log(this.videoList.length)
      })
    },
    // 上一级
    onLastGrouping() {
      this.videoList = []
      var pid = this.pgid[this.pgid.length - 2]
      // 加载子分组
      this.getGrouping(pid)
      // 加载视频
      getVideoListNot({
        gid: pid,
        page: this.page
      }).then(res => {
        this.videoList = res.data
        this.videoListIndex(1)
      })
      // 删除最后一个分组数据
      this.pgid = this.pgid.slice(0, -1)
    },
    // 加载视频分组子文件夹(子分组)
    getGrouping(pid) {
      getDictionariesList({
        grouping: '有声阅读',
        pid: pid
      }).then(res => {
        this.childGroup = res.data
      })
    },
    // 分页
    videoListIndex(e) {
      this.videoListTemp = this.videoList.slice((e - 1) * 20, e * 20)
    }
  }
}
</script>

<style lang="less" scoped>
//滚动条
.scrollbar {
  // height: 100%;
  margin-bottom: 50px;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
// 禁止复制
* {
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}
.search {
  position: absolute;
  right: 40px;
  top: 70px;
  z-index: 9;
}
.video-list {
  margin: 0px 20px 40px 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab {
  // padding: 20px 40px;
  // height: 100%;
}
.tab-name {
  font-size: 18px;
}
.video {
  position: relative;
  // height: 150px;
  width: 230px;
  // margin: 0px 20px;
  background: white;
  border-radius: 10px;
}
.video-img {
  // position: absolute;
  height: 150px;
  width: 230px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  // top: 0xp;
  // left: 0px;
  // border-radius: 5px;
}
.content {
  // position: absolute;
  // bottom: 0px;
  width: 100%;
  // background: rgba(0, 0, 0, 0.8) none repeat scroll 0% 0%;
  // color: #333333;
  .video-name {
    // position: absolute;
    // left: 0px;
    // bottom: 40px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
  }
  .video-tag {
    // position: absolute;
    // left: 0px;
    // bottom: 20px;
    font-size: 12px;
    color: #999999;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
}
.us-tv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  .img {
    border-radius: 10px;
    width: 200px;
    height: 300px;
  }
  .name {
    margin-top: 5px;
    font-size: 12px;
    color: #535353;
  }
}
.login-div {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(97, 97, 97, 0.6);
  z-index: 10;
  overflow: hidden;
}
.login {
  width: 500px;
  // height: 340px;
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 20px;
  .back {
    position: absolute;
    left: 20px;
    top: 20px;
  }
}
.hl {
  font-size: 36px;
  color: white;
  font-weight: bold;
}
.hl-login {
  font-size: 18px;
  color: #e7e7e7;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: bold;
}
.account {
  margin-top: 60px !important;
}
.input {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
}
.savePass {
  position: absolute;
  left: 0px;
}
.forgetPass {
  position: absolute;
  right: 0px;
}
.login-but {
  margin-top: 30px;
  width: 300px;
}
.float {
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.set-div {
  width: 500px;
  height: 500px;
}
.login-hl {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c61200;
  width: 100%;
  height: 300px;
  border-radius: 10px 10px 100% 100%;
  position: absolute;
  top: -100px;
}
.bott {
  position: fixed;
  height: 50px;
  width: 100%;
  background: #b62b1a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  bottom: 0px;
  left: 0px;
  font-size: 12px;
  z-index: 100000;
}
.bott a {
  color: white;
  margin-left: 10px;
}
.app-fixed {
  position: fixed;
  bottom: 80px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c61200;
  border-radius: 10px 0px 0px 10px;
  .image {
    border-radius: 10px 0px 0px 10px;
    width: 40px;
    height: 40px;
  }
  span {
    font-size: 12px;
    color: white;
    padding: 2px;
  }
}
.thirdparty {
  margin-top: 10px;
}
.weChat {
  display: flex;
  justify-content: center;
  align-items: center;
}
.weChat span {
  font-size: 12px;
  color: #525252;
  margin-top: 5px;
}
// 封面
.book {
  // 左右上下居中
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // background: #343434;
  border-radius: 3px;
  cursor: pointer;
  .book-div {
    position: relative;
  }
  .img {
    height: 300px;
    width: 220px;
  }
  .info {
    // margin-top: -160px;
    margin-left: 20px;
    .info-0 {
      margin-top: 0px;
      font-size: 20px;
      color: black;
    }
    .info-1 {
      font-size: 20px;
      color: rgb(44, 44, 44);
    }
    font-size: 14px;
    color: rgb(88, 88, 88);
    // font-weight: bold;
    span {
      margin-top: 10px;
    }
  }
  .start {
    // margin-top: -100px;
    margin-left: 40px;
    // height: 500px;
    width: 200px;
    // background: #bc3a1e;
    .cata-t {
      font-size: 20px;
      color: black;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .cata {
      margin-top: 5px;
      font-size: 12px;
      color: rgb(82, 82, 82);
    }
  }
  .read {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0px;
    right: 20px;
    width: 200px;
    height: 50px;
    background: #ac1114;
    border-radius: 5px;
    color: white;
  }
}
// 电子书阅读
.read {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  padding: 20px;
  .cata {
    padding: 10px;
    // background: rgb(238, 238, 238);
    // width: 300px;
  }
  .cata1 {
    cursor: pointer;
    padding: 4px;
    font-size: 14px;
    font-weight: bold;
  }
  .cata2 {
    cursor: pointer;
    padding: 4px;
    font-size: 12px;
    margin-left: 10px;
  }
  .body {
    background: white;
    padding: 20px;
    // width: 100%;
    height: 100%;
    margin-bottom: 50px;
  }
}
.service-QrCode {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.493);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  .qrCode-view {
    width: 320px;
    background: white;
    padding: 0px s0px 20px 0px;
    border-radius: 20px;
    // height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .qrCode-img {
    border-radius: 20px;
  }
  .qrCode-text {
    font-size: 12px !important;
    // font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
}
.tab-name {
  font-size: 14px !important;
}
</style>

<style>
.el-tabs__item {
  color: #999999 !important;
}
.el-tabs__item.is-active {
  color: white !important;
  z-index: 1;
}
.el-tabs__active-bar {
  height: 40px;
  border-radius: 5px 5px 0px 0px;
  /* padding: 10px;
  margin-left: -10px; */
}
.el-tabs__content {
  overflow: hidden;
  position: relative;
  height: calc(100% - 95px);
}
.el-pager li {
  background: transparent;
}
.el-pagination button:disabled {
  background: transparent;
}
.el-pagination .btn-next,
.el-pagination .btn-prev {
  background: transparent;
}
.el-pagination .btn-next,
.el-pagination .btn-prevZ {
  background: transparent;
}
</style>
